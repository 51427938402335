import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserInfoQuery } from '../user-info/user-info.query';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(private translate: TranslateService, infoQuery: UserInfoQuery) {
    translate.addLangs(['en-US', 'cs', 'sk', 'de', 'hr', 'ro']);
    translate.setDefaultLang('en-US');

    infoQuery.language$.pipe(distinctUntilChanged()).subscribe(lang => this.setLanguage(lang));
  }

  private setLanguage(lang: string) {
    if (!lang) {
      if (this.translate.langs.includes(this.translate.getBrowserCultureLang())) {
        lang = this.translate.getBrowserCultureLang();
      } else {
        lang = 'en-US';
      }
    }

    this.translate.use(lang);
  }
}
